import { IAccLookupPayload, IElectricityLookupPayload } from "../interfaces/auth";
import { UPDATE_STORE_BALANCE } from "../redux/actions";
import useAxiosPrivate from "./useAxiosPrivate";
import { useAppDispatch, useAppSelector } from "./useStore";
import {
    DATA_BUNDLE_LIST_URL,
    BANK_LIST_URL,
    ACCOUNT_LOOKUP_URL,
    CABLE_TV_PACKAGES_URL,
    CABLE_TV_CUSTOMER_LOOKUP_URL,
    ELECTRICITY_DISCOS_URL,
    ELECTRICITY_CUSTOMER_LOOKUP_URL,
    WALLET_TRANSACTION_HISTORY_URL,
    GET_TRANSACTIONS_GLOBAL_REPORT_URL
} from "../api/urlConfig";
import { RootState } from "../redux/store";
import { capitalize, decryptReduxValue, encryptData, getAbortSignalConfig } from "../utils/functions";
import { APP_ACCT_TYPES, NAIRA_SYMBOL, NON_WALLET_ACCOUNT, TRANSACTION_TYPES, WALLET_ACCOUNT } from "../utils/constants";
import { globalConfig } from "../api/axios";
import { setAccounts } from "../redux/features/userAccountsSlice";
import useAuth from "./useAuth";
import { ACTIVE_ACCOUNT_CATEGORIES } from "../utils/config";

function useDataInfo(){
    const dispatch = useAppDispatch();
    const axiosPrivate = useAxiosPrivate();
    const authRedux: any = useAppSelector((state: RootState) => state.auth);
    const {getAccountProfile} = useAuth();

    const getDataPacksList = async (abortSignal: AbortSignal) => {
        return axiosPrivate.get(DATA_BUNDLE_LIST_URL!, {
            ...getAbortSignalConfig(abortSignal),
            ...globalConfig
        });
    }

    const getBanksList = async (abortSignal: AbortSignal) => {
        return axiosPrivate.get(BANK_LIST_URL!, { ...globalConfig });
    }

    const accountLookup = async (formValues: IAccLookupPayload, abortSignal: AbortSignal) => {
        return axiosPrivate.post(ACCOUNT_LOOKUP_URL!, encryptData(formValues), {
            ...getAbortSignalConfig(abortSignal), 
            ...globalConfig
        });
    }

    const updateBalanceInStore = (newBalance: string) => {
       // dispatch(updateStoreBalance(newBalance));

        getAccountProfile(decryptReduxValue(authRedux.authAccountNumber)).then((accountProfile: any) => { 
            
            // Update all account balances in user account
            getAllUserAccounts(accountProfile.data);
        })
    }

    const getCableTvPackages = async () => {
        return axiosPrivate.get(CABLE_TV_PACKAGES_URL!, { ...globalConfig });
    }

    const cabletvCustomerLookup = async (customerId: string) => {
        return axiosPrivate.post(CABLE_TV_CUSTOMER_LOOKUP_URL!, encryptData({ customerId }), globalConfig);
    }

    const getAllDiscos = async (abortSignal: AbortSignal) => {
        return axiosPrivate.get(ELECTRICITY_DISCOS_URL!, {
            ...getAbortSignalConfig(abortSignal), 
            ...globalConfig
        });
    }

    const electricityCustomerLookup = async (reqPayload: IElectricityLookupPayload) => {
        return axiosPrivate.post(ELECTRICITY_CUSTOMER_LOOKUP_URL!, encryptData(reqPayload), globalConfig);
    }

    const getSourceAccount = (accountType?: string) => {
        let sourceAccount = "";

        // If the account type is not specified, use wallet
        if(!accountType){
            sourceAccount = `Wallet - ${authRedux.user.wallet_accts.agentcode?.slice(1)} - ${NAIRA_SYMBOL}${authRedux.user.wallet_accts.walletbalance}` 
        }
        else {
            let matchedAccount = authRedux.user.non_wallet_accts.find((acct: any) => (acct.accountType.toUpperCase() === accountType));

            const { accountType, accountNo, accountBalance } = matchedAccount;

            sourceAccount = `${capitalize(matchedAccount.accountType)} - ${accountNo} - ${NAIRA_SYMBOL}${accountBalance}`
        }

        return sourceAccount;
    }

    const getAllUserAccounts = (userPayloadObj: Record<string, any>) => {

        const { wallet_accts, non_wallet_accts } = userPayloadObj;
        const { agentcode, agentname, walletbalance, kyctier, bvn } = wallet_accts;
        var accountsObj: {[key: string]: string} = {};

        // Setup wallet account
        if(Object.entries(wallet_accts).length > 0){
            const wallet_acct_details = `${agentcode.slice(1)}|Wallet|${walletbalance}|${agentname}|${WALLET_ACCOUNT}|${kyctier}|${bvn}|ACTIVE`;
            accountsObj[agentcode.slice(1)] = wallet_acct_details;   
        }

        // Setup non wallet account
        if(non_wallet_accts.length > 0){
            non_wallet_accts.forEach((account: any) => {
                const { accountNo, accountType, accountBalance, customerName, kycTier, bvn, category } = account;
                accountsObj[accountNo] = `${accountNo}|${accountType}|${accountBalance}|${customerName}|${NON_WALLET_ACCOUNT}|${kycTier}|${bvn}|${ACTIVE_ACCOUNT_CATEGORIES!.split('*').includes(category) ? "ACTIVE" : ""}`;
            })
        }

        // Save user accounts object to redux store
        dispatch(setAccounts(accountsObj));

        return accountsObj;
    }

    const getTransactionHistory = async (reqPayload: any) => { 

        return axiosPrivate.post(WALLET_TRANSACTION_HISTORY_URL!, encryptData(reqPayload), globalConfig);
    }

    const getTransactionsGlobalReport = async (reqPayload: any) => { 

        return axiosPrivate.post(GET_TRANSACTIONS_GLOBAL_REPORT_URL!, encryptData(reqPayload), globalConfig);
    }

    return {
        getDataPacksList,
        getBanksList,
        accountLookup,
        updateBalanceInStore,
        getCableTvPackages,
        cabletvCustomerLookup,
        getAllDiscos,
        electricityCustomerLookup,
        getSourceAccount,
        getTransactionHistory,
        getAllUserAccounts,
        getTransactionsGlobalReport
    }
}

export default useDataInfo;