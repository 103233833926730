import { useLayoutEffect, useState } from "react";
import parse from 'html-react-parser';

const PrivacyPolicy = () => {
    const [pageContents, setPageContents] = useState("");

  /**
  * @param {String} url - address for the HTML to fetch
  * @return {String} the resulting HTML string fragment
  */
    async function fetchHtmlAsText(url: string) {
        return (await fetch(url)).text();
    }

    // this is your `load_home() function`
    async function loadHome() {
        const contentDiv = await fetchHtmlAsText(`${window.location.origin}/privacy-policy.html`);
        setPageContents(contentDiv);
    }

    useLayoutEffect(() => {
        document.title = "Information Security | PRIVACY POLICY";
        loadHome();
    },[])
  
    if(pageContents === "") return <></>

  return <>
            { parse(pageContents) }
        </>
}

export default PrivacyPolicy