import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import SiteLogo from "../../assets/mmpsb-logo.png";
import useAuth from "../../hooks/useAuth";
import CryptoEncrypt from "../../utils/crypto"
import { useAppDispatch } from "../../hooks/useStore";
import { toastFailure, toastSuccess } from "../../utils/toasts";
import { APP_RESPONSES, PIN_TYPES, SECURITY_QUESTIONS, TOOLTIP_ERROR_MESSAGE } from "../../utils/constants";
import "./styles/bvnsignup.scss";
import { encryptData, prependCountryCode, successResponse } from "../../utils/functions";
import { AxiosResponse } from "axios";
import DatePicker from 'react-datepicker';
import { REGISTER_STAGE } from "../../utils/constants";
import { NewUserRecord } from "../../interfaces/auth";
import useOTP from "../../hooks/useOTP";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import StageBC from "../../components/StageBreadCrumb/StageBC";

// const tooltipStyles = { backgroundColor: "#f0ad4e", color: "#000", opacity: 1 };

const tooltipStyles = { backgroundColor: "#2dbe60", color: "#FFF", opacity: 1 };

function BVNSignup() {
  const [formValues, setFormValues] = useState({
    accountNumber: "",
    otp: "",
    password: "",
    confirmPassword: "",
    pin: "",
    transactionPin: "",
    confirmTransactionPin: "",
    dob: "",
    bvn: "",
    bvnPhoneNumber: "",
    question1: "",
    answer1: "",
    question2: "",
    answer2: "",
    email: "",
    confirmEmail: "",
    pinTypeToValidate: "",
    channelPin: "",
    digitalAccessCode: ""
  });
  const [registerStage, setRegisterStage] = useState<string>(REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const { 
    saveUserRecord, 
    doValidateBVNDetails, 
    getSecurityQuestions,
    checkIfRegistered
  } = useAuth();

  const { sendOTP, validateOTPReq } = useOTP();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tier, setKycTier] = useState<string>("");
  const [passwordErrors, setPasswordErrors] = useState<string>("");
  const [tooltipMessage, setTooltipMessage] = useState<string>("");
  const [currStageNum, setCurrStageNum] = useState<number>(0);


  const validatePassword = (passwordVal: string) => {
    /**
     *  Password cannot be less than 8
        Must contain an Uppercase
        Must contain a lowercase
        Must contain a special character
     */
        const errors = {
          lessThan8: "",
          uppercase: "",
          lowercase: "",
          special: ""
        }
    
        const { password } = formValues;
    
        errors.lessThan8 = password.length < 8 ? 'must contain at least 8 characters' : '';
        errors.uppercase = !Boolean(password.match(/[A-Z]+/g)) ? 'must contain an upper case character' : '';
        errors.lowercase = !Boolean(password.match(/[a-z]+/g)) ? 'must contain a lower case character' : '';
        errors.special = !Boolean(password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g)) ? 'must contain a special character' : '';
    
        const allErrors = Object.values(errors).filter(error => error !== "");

        return allErrors;
  }

  const handlePasswordValidation = () => {
    // This should return an array with errors or empty array if none
    const allErrors = validatePassword(formValues.password);

    let formattedErrorMsg = "";

    if(allErrors.length){
      formattedErrorMsg = `<section> ${allErrors.length > 0 ? 'Password: <br><span>x</span>&nbsp; ': ''} ${ allErrors.join('<br><span>x</span>&nbsp; ') } </section>`
    }
    
    // Set the password errors value
    setPasswordErrors(formattedErrorMsg)
  }
  
  // Monitors all form input changes and validates for each user entry
  // This hook focuses on getting information to be displayed on react tooltip 
  useEffect(() => {
    const { accountNumber, otp } = formValues;

    // This section determines what is shown in the tooltip
    switch (registerStage) {
      case REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN:
        // if account number's length is less than 10, display tooltip
        if(accountNumber && accountNumber.length < 10) {
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.ACCOUNT_NUMBER)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.OTP_SCREEN:
        // if otp value length is less than 6, display tooltip
        if(otp && otp.length < 6){
          setTooltipMessage(TOOLTIP_ERROR_MESSAGE.OTP)
        }else {
          setTooltipMessage("")
        }
        break;

      case REGISTER_STAGE.PASSWORD_SCREEN:
        handlePasswordValidation()
        break;

      default:
        break;
    }
    
  },[
    formValues, 
    registerStage
  ])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // This check ensures that only numbers are entered
    if((name === 'accountNumber') || (name === 'otp')
    ) {
      if(isNaN(Number(value))) return
    }

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetLoading = () => {
    setLoadSpinner(false);
  };

  const handleServerResponseError = (error: any) => {
    toastFailure(error.response.data.responseMessage);
    resetLoading();
  }

  const validateOTP = async() => {
    if(formValues.otp === ""){
      toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
      resetLoading();
      return;
    }

    try {
      // Check tier to determine how to get the user's phone number
      // const accountMatched = tier === "3" ? formValues.accountNumber : formValues.bvnPhoneNumber;

      const response = await validateOTPReq(formValues.otp.toString(), formValues.accountNumber)
      
      const { responsecode, responseCode} = response.data;

      resetLoading();

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response.data.responseMessage);
        return;
      }

      // This call here is to initialize the required constraints for a user's password
      handlePasswordValidation();

      setRegisterStage(REGISTER_STAGE.CREATE_PASSWORD_SCREEN);

    } catch (error: any) {
      toastFailure(error.response?.data?.responseMessage);
      resetLoading();
    }
    
  }

  

  const hideSpinner = () => setLoadSpinner(false);

  const doCheckIfRegistered = async () => {
    if (
      isNaN(Number(formValues.accountNumber)) ||
      formValues.accountNumber.length !== 10
    ) {
      toastFailure(APP_RESPONSES.INVALID_ACCOUNT_NUMBER);

      resetLoading();
      return;
    }

    displaySpinner();
    
    // Check if registered
    try{
      const response = await checkIfRegistered(formValues.accountNumber);
      const { responseCode, responsecode, tier } = response.data;

      if(!successResponse([responsecode, responseCode])){
        toastFailure(response.data.responseMessage);
        resetLoading();
        return
      }

      sendOTP(formValues.accountNumber);
       
      setRegisterStage(REGISTER_STAGE.OTP_SCREEN);

      setKycTier(() => {
        return tier;
      });

      hideSpinner();
    }catch(err: any){
      toastFailure(APP_RESPONSES.DEFAULT_SERVER_ERROR);
      resetLoading();
      return;
    }
    
  }

  const handleSubmit = async () => {
    displaySpinner();

    setTimeout(async() => {
      switch (registerStage) {
        case REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN:
          await doCheckIfRegistered();
          break;
        case REGISTER_STAGE.OTP_SCREEN:
          validateOTP();
          break;
        case REGISTER_STAGE.CREATE_PASSWORD_SCREEN:
          activateAccount();
          break;
        default:
          break;
      }
    }, 2000);
    
  };

  const activateAccount = async () => {
    // Check if password is valid before proceeding
    if ((passwordErrors.length) || (formValues.password !== formValues.confirmPassword)) {
      toastFailure(APP_RESPONSES.PASSWORD_MISMATCH);
      hideSpinner();
      return;
    }

    const {password, accountNumber} = formValues;

    const requestPayload = {
      "password": CryptoEncrypt.encryptValue(password),
      "userId": accountNumber,
      "clientType": "SELFCARE",
    }

    try {
      const response = await saveUserRecord(requestPayload);

      const { responseCode, responsecode } = response.data;

      if(successResponse([responseCode, responsecode])){
        toastSuccess(`Welcome, Your account has been enrolled successfully. Kindly login into your dashboard.`);

        resetLoading();
        
        navigate("/login");
      }else{
        resetLoading();
        toastFailure(response.data.responseMessage);
      }
    } catch (error: any) {
      resetLoading();
      toastFailure(error.response.data.responseMessage);
    }
  }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
  };

  const validatePasswordConstraints = () => {
    // If the password field value changes, call validate
    // This updates the constraints realtime on the page
    handlePasswordValidation();
  }

  // This hooks monitors the registration stage and updates necessary dependencies
  useEffect(() => {
    setCurrStageNum(currStageNum + 1);
  },[registerStage]);

  return (
    <>
    <div id="main-wrapper">
      <div id="content" className="container-padding-x">
 
          <div className="container-fluid px-0">
            <div className="row g-0 min-vh-100">
              {/* <div className="col-md-6 img-div">
                <div className="hero-wrap d-flex align-items-center h-100">
                  <div
                    className="hero-bg hero-bg-scroll"
                    style={{
                      backgroundImage: "url('/images/bg/image-9.jpg')",
                    }}
                  ></div>
                </div>
              </div> */}
              <div className="col-md-12 d-flex align-items-center">
                <div className="container my-4">
                  <div className="row g-0">
                    <div className="col-4 col-lg-4 mx-auto container-width">
                      <div className="w-100 text-center">
                        <Link to="/" className="site-logo">
                          <img src={SiteLogo} className="w-50 my-4" />
                        </Link>
                      </div>
                      <h3 className="fw-400 mb-4">Sign Up</h3>
                      
                      {/* This stage breadcrumb shows number of steps completed and left */}
                      {/* <StageBC stageCount={getRegStageLength()} currentStage={currStageNum} className="mb-4" /> */}

                      <form id="registerForm" onSubmit={ e => {e.preventDefault()} }>
                        {
                          registerStage === REGISTER_STAGE.ACCOUNT_NUMBER_SCREEN &&
                          <>
                            <div className="mb-3">
                              <label htmlFor="account-number" className="form-label text-3 text-lh">
                                <p style={{ marginBottom: "0rem" }}>Enter your 10-digit account number.</p>
                              </label>
                            
                              <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                id="account-number"
                                name="accountNumber"
                                required
                                placeholder="Enter your Account Number"
                                value={formValues.accountNumber}
                                onChange={(e) => handleChange(e)}
                                data-tooltip-id="account-number-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>
      
                            <ReactTooltip 
                              id="account-number-tooltip" 
                              isOpen={tooltipMessage.length > 0}
                              style={tooltipStyles}
                              />
                          </>
                        }

                        

                        {registerStage === REGISTER_STAGE.OTP_SCREEN && (
                          <>
                            <div className="mb-3">
                              <label htmlFor="otp" className="form-label">
                                Enter OTP
                              </label>
                              <input
                                type="text"
                                maxLength={6}
                                className="form-control"
                                id="otp"
                                name="otp"
                                required
                                placeholder="OTP"
                                value={formValues.otp}
                                onChange={handleChange}
                                data-tooltip-id="otp-tooltip" 
                                data-tooltip-content={tooltipMessage}
                              />
                            </div>

                            {/* <ReactTooltip id="otp-tooltip" isOpen={tooltipMessage.length > 0} style={tooltipStyles} /> */}
                            {/* <p>
                              Dial <span className="text-success">*995#</span> to get your OTP.
                            </p> */}
                          </>
                        )}
                        
                        {registerStage === REGISTER_STAGE.CREATE_PASSWORD_SCREEN && (
                          <>
                            {/* This conditions show up when the user password has not met the required criteria */}
                            <p className={`password-rules ${passwordErrors.length ? '':'d-none'}`} dangerouslySetInnerHTML={{__html: passwordErrors}}>
                              
                            </p>

                            <div className="mb-3">
                              <label htmlFor="password" className="form-label">
                                Create new Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                required
                                placeholder="Password"
                                onChange={handleChange}
                                onKeyUp={validatePasswordConstraints}
                                data-tooltip-id="password-tooltip" 
                                data-tooltip-html={passwordErrors}
                              />
                            </div>

                            {/* <ReactTooltip id="password-tooltip" isOpen={passwordErrors.length > 1} style={tooltipStyles} /> */}

                            <div className="mb-3">
                              <label htmlFor="confirmPassword" className="form-label">
                               Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                required
                                placeholder="Confirm Password"
                                onChange={handleChange}
                              />
                            </div>
                          </>
                        )}

                    
                        {/* This button is displayed for every form page */}
                        <div className="d-grid mt-4 mb-3">
                          <button
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={handleSubmit}
                          >
                            {loadSpinner ? (
                              <span className="d-flex justify-content-center w-auto">
                                <Spinner />
                              </span>
                            ) : (
                              registerStage !== REGISTER_STAGE.CREATE_PASSWORD_SCREEN
                              ? <span className="d-flex"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span> 
                              : <span className="d-flex"><i className="fas fa-upload mb-015 me-2 align-self-center"></i> Submit</span>
                            )}
                          </button>
                        </div>

                        <p className="text-3 text-center text-muted">Already enrolled ? <Link to="/login" className="btn-link">Log In</Link></p>
                        
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BVNSignup;