import { ReactElement, useLayoutEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { APP_RESPONSES } from '../../utils/constants';
import { toastFailure } from '../../utils/toasts';
import { useAppSelector } from '../../hooks/useStore';
import { RootState } from '../../redux/store';

const ProtectedRoutes = ({children}: {children: ReactElement}) => {
  const {userIsAuth} = useAuth();
  const authRedux = useAppSelector((state: RootState) => state.auth);  

  if(!userIsAuth()){
    return <Navigate to="/login" />;
  }
  else if(userIsAuth()){ 
    return children; 
  }else{
    return <Navigate to="/" />;
  }
};

export default ProtectedRoutes