import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { RootState } from "../redux/store";
import { useDispatch } from "react-redux";
import { LOGOUT, REGISTER_TOKEN, SESSION_TIMEOUT, UPDATE_ACCESS_TOKEN } from "../redux/actions";
import { useAppSelector } from "./useStore";
import useTimeout from "./useTimeout";
import { toastFailure } from "../utils/toasts";
import { APP_RESPONSES } from "../utils/constants";
import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import useAuth from "./useAuth";
import { decryptReduxValue, successResponse } from "../utils/functions";
import { BASE_URL, TOKENS_URL } from "../api/urlConfig";
import { globalConfig } from "../api/axios";
import customAxiosApi from "../api/axios";
import Crypto from "../utils/crypto";
import log from "loglevel";

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'text/plain',
    'clientid': 'ib'
  },
});

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const dispatch = useDispatch();
  const {
    handleTimeout,
    timeoutFunc,
    useClearTimeout
  } = useTimeout();
  

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      async (config: InternalAxiosRequestConfig & any) => {

        //const controller = new AbortController();
        // console.log(`Bearer ${decryptReduxValue(authRedux.tokens).accessToken}`);
        
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${decryptReduxValue(authRedux.tokens).accessToken}`;
          // console.log('Request interceptor: ', config);
          //return config;
        }

        // if(!authRedux?.tokens.accessToken){
          
        //     const response = await customAxiosApi.post(TOKENS_URL!, controller.signal, globalConfig);
          
        //     const { responseCode, responsecode } = response.data;

        //     if(successResponse([responseCode, responsecode])){
        //       const token = {
        //         accessToken: response.data.accessToken,
        //       };
      
        //       dispatch({ type: REGISTER_TOKEN, payload:token });

        //       if (!config.headers["Authorization"]) {
        //         // console.log('2',token.accessToken);
        //         config.headers["Authorization"] = `Bearer ${token.accessToken}`;
        //       }
        //     }
        // }else{
        //   if (!config.headers["Authorization"]) {
        //     config.headers["Authorization"] = `Bearer ${authRedux?.tokens.accessToken}`;
        //     //return config;
        //   }
        // }

        return config;
      },
      (error : any) => Promise.reject(error)
    );

    // Timeout handling
    const timeoutRequestIntercept = axiosPrivate.interceptors.request.use(timeoutFunc);

    const responseIntercept = axiosPrivate.interceptors.response.use(
      async (response: AxiosResponse<any, any>) => {
        // console.log('In private interceptor: ', response, Crypto.decryptValue(response.data));

        const resolvedResponseData = await response.data;

        if(typeof resolvedResponseData === "string"){
          try {
            response.data = JSON.parse(Crypto.decryptValue(resolvedResponseData));  
          } catch (error) {
            response.data = Crypto.decryptValue(resolvedResponseData);
          }
        }
        
        // console.log('Response in interceptor: ', response);
        return response;
      },
      async (error) => {
        // const { config } = error;
    
        // if (!config || !config.retry) {
        //   return Promise.reject(error);
        // }
        // config.retry -= 1
        // const delayRetryRequest = new Promise<void>((resolve) => {
        //   setTimeout(() => {
        //     console.log("retry the request", config.url);
        //     //
        //     resolve();
        //   }, config.retryDelay || 1000)
        // })
        // return delayRetryRequest.then(() => axiosPrivate(config));
        console.log("Error occurred", error);

        log.error(JSON.stringify(error));

        const { config } = error;

        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 401) 
        ) {

          try{
            const newAccessToken = await refresh();

            // Save the new access token to redux store
            dispatch({type: UPDATE_ACCESS_TOKEN, payload: newAccessToken})

            config.headers["Authorization"] = `Bearer ${newAccessToken}`;
            
            return axiosPrivate(config);

          }catch(err: any){
            if(err.response.status !== 200 && authRedux.isLoggedIn){
              console.log('Error in catch block');
              dispatch({ type: SESSION_TIMEOUT });
              throw new axios.Cancel('');
            }

            //return Promise.resolve();
          }

        }

         if (!config || !config.retry) {
          return Promise.reject(error);
        }
        
        config.retry -= 1
        
        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            console.log("retry the request", config.url);
            //
            resolve();
          }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => axiosPrivate(config));
        //return Promise.reject(error);
      }
    );


    const timeoutResponseIntercept = axiosPrivate.interceptors.response.use(useClearTimeout, handleTimeout)

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.request.eject(timeoutRequestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
      axiosPrivate.interceptors.response.eject(timeoutResponseIntercept);
    };
  }, [authRedux, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
