 import { ChangeEvent, useMemo, useRef, useState } from 'react';
import './styles/forgotpassword.scss';
import { APP_RESPONSES, CLIENT_TYPES, RESET_PASSWORD_STAGE } from '../../utils/constants';
import Spinner from '../../components/Spinner/Spinner';
import { toastFailure } from '../../utils/toasts';
import { encryptData, successResponse } from '../../utils/functions';
import useAuth from '../../hooks/useAuth';
import SiteLogo from "../../assets/mmpsb-logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OperationResponse from '../../components/OperationResponse/OperationResponse';

const ForgotPassword = () => {
  const [resetStage, setResetStage] = useState(RESET_PASSWORD_STAGE.ACCOUNT_NUMBER_SCREEN);
  const [loadSpinner, setLoadSpinner] = useState(false)
  const { doResetPassword, doValidateOTPToAccount, doValidatePIN, doValidateAccountAndSendOTP } = useAuth();
  const pageHeaderRef = useRef<any>();
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
    otp: "",
    pin: "",
    accountNumber: ""
  });
  const [resetPasswordResult, setResetPasswordResult] = useState({
    success: false,
    message: ""
  });
  const [passwordErrors, setPasswordErrors] = useState<string>("");
  const navigate = useNavigate();
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // This check ensures that only numbers are entered
    if(
     (event.target.name === 'otp')
    || (event.target.name === 'accountNumber')
    ) {

      if(isNaN(Number(event.target.value))) return

    }

    setFormValues((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
    }));
  }

  const validatePasswordConstraints = () => {
    // If the password field value changes, call validate
    // This updates the constraints realtime on the page
    handlePasswordValidation();
  }

  const validateOTPToAccount = async() => {
    if(formValues.otp === ""){
        toastFailure(APP_RESPONSES.INVALID_OTP_CODE);
        return
    }
    
    toast.dismiss();

    displaySpinner();
    try {
        const response = await doValidateOTPToAccount({input: formValues.otp, accountNumber: formValues.accountNumber});

        const { responsecode, responseCode} = response.data;

        if(!successResponse([responseCode, responsecode])){
            toastFailure(response.data.responseMessage);
            hideSpinner();
            return;
        }

        // This call here is to initialize the required constraints for a user's password
        handlePasswordValidation();

        setResetStage(RESET_PASSWORD_STAGE.NEW_PASSWORD_SCREEN);
        hideSpinner();
    } catch (error: any) {
        toastFailure(error.response.data.responseMessage);
        hideSpinner();
    }
    
  }

  const validatePIN = async() => {
    if(formValues.pin === ""){
        toastFailure(APP_RESPONSES.INVALID_PIN);
        return;
    }

    toast.dismiss();

    displaySpinner();

    try {
        const response = await doValidatePIN({pin: encryptData(formValues.pin), accountNumber: formValues.accountNumber, clientType: CLIENT_TYPES.SELF_CARE});

        const { responsecode, responseCode } = response.data;
        
        if(!successResponse([responseCode, responsecode])){
            toastFailure(response.data.responseMessage);
            hideSpinner();
            return;
        }

        setResetStage(RESET_PASSWORD_STAGE.NEW_PASSWORD_SCREEN);
        hideSpinner();
    } catch (error: any) {
        console.log(error);

        toastFailure(error.response?.data?.responseMessage);
        hideSpinner();
    }
    
  }

  const validatePassword = () => {
    /**
     *  Password cannot be less than 8
        Must contain an Uppercase
        Must contain a lowercase
        Must contain a special character
     */
        const errors = {
          lessThan8: "",
          uppercase: "",
          lowercase: "",
          special: ""
        }
    
        const { password } = formValues;
    
        errors.lessThan8 = password.length < 8 ? 'must contain at least 8 characters' : '';
        errors.uppercase = !Boolean(password.match(/[A-Z]+/g)) ? 'must contain an upper case character' : '';
        errors.lowercase = !Boolean(password.match(/[a-z]+/g)) ? 'must contain a lower case character' : '';
        errors.special = !Boolean(password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g)) ? 'must contain a special character' : '';
    
        const allErrors = Object.values(errors).filter(error => error !== "");

        return allErrors;
  }

  const handlePasswordValidation = () => {
    // This should return an array with errors or empty array if none
    const allErrors = validatePassword();

    let formattedErrorMsg = "";

    if(allErrors.length){
      formattedErrorMsg = `<section> ${allErrors.length > 0 ? 'Password: <br><span>x</span>&nbsp; ': ''} ${ allErrors.join('<br><span>x</span>&nbsp; ') } </section>`
    }
    
    // Set the password errors value
    setPasswordErrors((prev) => (formattedErrorMsg))
  }

  const validateAccountAndSendOTP = () => {
    if(formValues.accountNumber === ""){
        toastFailure(APP_RESPONSES.INVALID_ACCOUNT_NUMBER);
        return;
    }

    try {
        doValidateAccountAndSendOTP({accountNumber: formValues.accountNumber});

        showOTPScreen();
    } catch (error: any) {
        console.log(error.response?.data?.responseMessage)
    }
    
  }

  const showOTPScreen = async () => {
    displaySpinner();

    setResetStage(RESET_PASSWORD_STAGE.OTP_SCREEN);

    hideSpinner();
  }

  const handleSubmit = async () => {

    switch(resetStage){
        case RESET_PASSWORD_STAGE.ACCOUNT_NUMBER_SCREEN:
            validateAccountAndSendOTP();
            break;
        case RESET_PASSWORD_STAGE.OTP_SCREEN:
            validateOTPToAccount();
            break;
        case RESET_PASSWORD_STAGE.NEW_PASSWORD_SCREEN:
            resetPassword();
            break;
        default:
            break;
    }
    
  }

  const resetPassword = async () => {
    displaySpinner();

    // Check if password is valid before proceeding
    if ((passwordErrors.length) || (formValues.password !== formValues.confirmPassword)) {
        toastFailure(APP_RESPONSES.PASSWORD_MISMATCH);
        hideSpinner();
        return;
    }

    const reqPayload = {
        userId: formValues.accountNumber,
        password: encryptData(formValues.password),
    };

    try {
        const response = await doResetPassword(reqPayload);

        const {responsecode, responseCode} = response.data;

        if(!successResponse([responseCode, responsecode])){
            toastFailure(response.data.responseMessage);
            hideSpinner();
        }

        setResetPasswordResult(() => {
            return {
                success: successResponse([responseCode, responsecode]),
                message: response.data.responseMessage
            }
        })

        hidePageHeader();

        setResetStage(RESET_PASSWORD_STAGE.OPERATION_COMPLETED)
    } catch (error: any) {
        setResetPasswordResult(() => {
            return {
                success: false,
                message: error.response?.data?.responseMessage || APP_RESPONSES.DEFAULT_SERVER_ERROR
            }
        })

        hidePageHeader();

        setResetStage(RESET_PASSWORD_STAGE.OPERATION_COMPLETED)
        toastFailure(error.response?.data?.responseMessage || APP_RESPONSES.DEFAULT_SERVER_ERROR)
        hideSpinner();
    }
  }

  const hidePageHeader = () => {
    pageHeaderRef.current && pageHeaderRef.current.classList.add('d-none');
  }

  const hideSpinner = () => { setLoadSpinner(false) }

  const displaySpinner = () => {
    if (!loadSpinner) setLoadSpinner(true);
  };

  // This function redirects the user to the login page
  const goToLogin = () => navigate("/login")
  

  return (
    <div id="main-wrapper" className='container-fluid px-0'>
        <div className="row g-0 min-vh-100 container-padding-x">
            {/* <div className="col-md-6 img-div">
                <div className="hero-wrap d-flex align-items-center h-100">
                    <div
                    className="hero-bg hero-bg-scroll"
                    style={{
                        backgroundImage: "url('/images/bg/image-7.jpg')",
                    }}
                    ></div>
                </div>
            </div> */}
            <div className='col-md-12 d-flex align-items-center'>
                <div className="container my-4">
                  <div className="row g-0">
                    <div className="col-4 col-lg-4 mx-auto container-width">
                        <div className="w-100 text-center">
                            <Link to="/" className="site-logo">
                                <img src={SiteLogo} className="w-50 my-4" />
                            </Link>
                        </div>
                        <form id="reset-password" onSubmit={ e => {e.preventDefault()} }>
                            
                        <h3 className='text-left fw-400 mb-4' ref={pageHeaderRef}>Reset Password</h3>
                        
                        {resetStage === RESET_PASSWORD_STAGE.ACCOUNT_NUMBER_SCREEN && (
                            <>
                            <div className="mb-3">
                                <label htmlFor="accountNumber" className="form-label">
                                Enter Account Number
                                </label>
                                <input
                                type="text"
                                maxLength={10}
                                className="form-control"
                                id="accountNumber"
                                name="accountNumber"
                                required
                                placeholder="Account Number"
                                value={formValues.accountNumber}
                                onChange={handleChange}
                                />
                            </div>
                            </>
                        )}

                        {resetStage === RESET_PASSWORD_STAGE.OTP_SCREEN && (
                            <>
                            <div className="mb-3">
                                <label htmlFor="otp" className="form-label">
                                Enter OTP
                                </label>
                                <input
                                type="text"
                                maxLength={6}
                                className="form-control"
                                id="otp"
                                name="otp"
                                required
                                placeholder="OTP"
                                value={formValues.otp}
                                onChange={handleChange}
                                />
                            </div>
                            
                            </>
                        )}

                        {/* {resetStage === RESET_PASSWORD_STAGE.PIN_SCREEN && (
                            <>
                            <div className="mb-3">
                                <label htmlFor="pin" className="form-label">
                                Enter Internet Banking PIN
                                </label>
                                <input
                                type="password"
                                maxLength={4}
                                className="form-control"
                                id="pin"
                                name="pin"
                                required
                                placeholder="PIN"
                                value={formValues.pin}
                                onChange={handleChange}
                                />
                            </div>
                            </>
                        )} */}


                        {resetStage === RESET_PASSWORD_STAGE.NEW_PASSWORD_SCREEN && (
                            <>
                            {/* This conditions show up when the user password has not met the required criteria */}
                            <p className={`password-rules ${passwordErrors.length ? '':'d-none'}`} dangerouslySetInnerHTML={{__html: passwordErrors}}>
                              
                            </p>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">
                                Enter New Password
                                </label>
                                <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                required
                                value={formValues.password}
                                onChange={handleChange}
                                onKeyUp={validatePasswordConstraints}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="confirm-password" className="form-label">
                                Confirm New Password
                                </label>
                                <input
                                type="password"
                                className="form-control"
                                id="confirm-password"
                                name="confirmPassword"
                                required
                                value={formValues.confirmPassword}
                                onChange={handleChange}
                                />
                            </div>
                            </>
                        )}

                        {/* This button is displayed for every form page */}
                        { resetStage !== RESET_PASSWORD_STAGE.OPERATION_COMPLETED 
                            &&  <>
                                <div className="d-grid mt-4 mb-3">
                                    <button
                                    className="btn btn-primary d-flex justify-content-center"
                                    type="button"
                                    onClick={handleSubmit}
                                    >
                                    {loadSpinner ? (
                                        <span className="d-flex justify-content-center w-auto">
                                        <Spinner />
                                        </span>
                                    ) : 
                                    resetStage !== RESET_PASSWORD_STAGE.NEW_PASSWORD_SCREEN
                                    ? <span className="d-flex"><i className="fas fa-arrow-alt-circle-right mb-015 me-2 align-self-center"></i> Continue</span> 
                                    : <span className="d-flex"><i className="fas fa-upload mb-015 me-2 align-self-center"></i> Submit</span>
                                    }
                                    </button>
                                </div>
                                {/* <p className="text-3 text-center text-muted">Already have an account? <Link to="/login" className="btn-link">Log In</Link></p> */}
                                </>
                        }

                        {/* {resetStage === RESET_PASSWORD_STAGE.OPERATION_COMPLETED && (
                            <>
                            <div className="bg-white text-center rounded p-3 pt-sm-4 pb-sm-5 px-sm-5 mb-4">
                            <div className="my-4 d-flex flex-column align-items-center">
                            <p className={`${resetPasswordResult.success ? "text-success": "text-danger"} text-10 lh-1`}><i className={`fas ${resetPasswordResult.success ? "fa-check-circle": "fa-exclamation-triangle"}`}></i></p>
                            <p className={`${resetPasswordResult.success ? "text-success": "text-danger"} text-6 fw-500 lh-1`}>{ resetPasswordResult.success ? "Password Successfully Changed!" : "Operation Failed!" }</p>
                            
                            </div>
                            <p className=" mb-4">
                            { resetPasswordResult.success 
                                ?  <span className='tw-text-base tw-font-bold'>{resetPasswordResult.message}</span>
                                :  `NOT SUCCESSFUL: ${ resetPasswordResult.message }`
                            }
                            </p>
                            <Link to="/login" className='btn btn-primary w-100'>Go to Login Page</Link>
                            </div>
                            </>
                        )} */}

                        {resetStage === RESET_PASSWORD_STAGE.OPERATION_COMPLETED && 
                            <OperationResponse 
                                success={resetPasswordResult.success}
                                transResBody={resetPasswordResult.success 
                                    ?  <span className='tw-text-base tw-font-bold'>{resetPasswordResult.message}</span>
                                    :  `NOT SUCCESSFUL: ${ resetPasswordResult.message }`}
                                resetOperationProcess={ () => goToLogin() }
                            />
                        }
                        </form>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword