import React from "react";
import { classNames } from "./../../../../utils/functions";
import './styles/button.scss';

export function Button({ children, className, ...rest }: any) {
  return (
    <button
      type="button"
      className={classNames(
        "position-relative mod-inline-flex align-items-center mod-px-4 mod-py-2 mod-border mod-border-gray-300 mod-text-sm mod-font-medium mod-rounded-md mod-text-gray-700 bg-white hover:mod-bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function PageButton({ children, className, ...rest }: any) {
  return (
    <button
      type="button"
      className={classNames(
        "position-relative mod-inline-flex align-items-center mod-px-2 mod-py-2 mod-border mod-border-gray-300 bg-white mod-text-sm mod-font-medium mod-text-gray-500 hover:mod-bg-gray-50",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}