import { Ref } from 'react';
import { IUserFullTransactionHistory } from '../../../../interfaces/auth';
import { NAIRA_SYMBOL } from '../../../../utils/constants';

import './styles/receiptLayout.scss';

const ReceiptLayout = ({record, docRef}: {record: IUserFullTransactionHistory, docRef: any}) => {
  
  const {
    tran_id,
    amount,
    dest_account,
    dest_acct_name,
    source_account,
    tran_date,
    tran_desc,
    tran_type,
    source_account_name,
    source_institution_name
  } = record;

  const hashAccountNumber = (number: string) => {
    let hashedNumber = number.split("").splice(3,7).map((num, index) => {
      if(index === 2) return num + "XXXX";
      return num;
    });

    hashedNumber.splice(6, 1);

    return hashedNumber.join("");
  }

  return (
        <div className='receipt-container text-center d-flex justify-content-center align-items-start w-100' ref={docRef}>
        <div className="text-center mb-4 record-receipt">
        <div className="wrapper py-2">
          <div className="pt-4">
            <p className="text-success fw-400 lh-1 mb-3 title">Receipt</p>
          </div>

          <div className="divider">
            <span></span><span></span><span></span>
          </div>
          
          <p className={`text-2 response-body`}>
                        
            <div className="d-flex justify-content-between mb-2"><span>Transaction Id</span> <span className="fw-400">{tran_id}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Amount</span> <span className="fw-400">{NAIRA_SYMBOL}{amount}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Transaction Description</span> <span className="fw-400">{tran_desc}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Date</span> <span className="fw-400">{tran_date}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Source Account</span> <span className="fw-400">{ hashAccountNumber(source_account) }</span></div>
            <div className="d-flex justify-content-between mb-2"><span className='text-start' style={{ width: "50%" }}>Source Account Name</span> <span className="fw-400 text-end">{source_account_name}</span></div>
            <div className="d-flex justify-content-between mb-2"><span className='text-start' style={{ width: "50%" }}>Source Institution Name</span> <span className="fw-400 text-end">{source_institution_name}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Destination Account</span> <span className="fw-400">{ hashAccountNumber(dest_account) }</span></div>
            <div className="d-flex justify-content-between mb-2"><span className='text-start'>Destination Account Name</span> <span className="fw-400 text-end">{dest_acct_name}</span></div>
            <div className="d-flex justify-content-between mb-2"><span>Transaction Type</span> <span className="fw-400">{tran_type}</span></div>
          </p>
          <p className='footer' style={{ fontSize: "16px" }}>
            Powered By <strong>MoneyMaster PSB</strong>
          </p>
        </div>
      </div>
      </div>
  )

}

export default ReceiptLayout