import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import "./app.scss";
import { useAppDispatch } from "./hooks/useStore";
import { CLEAR_REDUX_STORE } from "./redux/actions";

function App() {
  const dispatch = useAppDispatch();

  // This hides the preloader after a few nano seconds
  useEffect(() => {
    

    setTimeout(() => {
      document.querySelector("#preloader")?.setAttribute("hidden", "true");
    }, 333);
  },[]);

  return (
    <>
      <div id="preloader">
        <div data-loader="dual-ring"></div>
      </div>

      <div id="">
        <Outlet />
      </div>

    </>
  );
}

export default App;
