import remote from 'loglevel-plugin-remote';
import { BASE_ACCESS_URL, LOG_LEVEL_URL } from '../api/urlConfig';

export const RESPONSE_CODES = {
    SUCCESS: "00",
    FAILURE: "99"
}

export const APP_RESPONSES = {
    DEFAULT_SERVER_ERROR: "Network Failure Occurred",
    INVALID_ACCOUNT_NUMBER: "Account number is invalid",
    PASSWORD_MISMATCH: "Passwords do not match",
    INVALID_OTP_CODE: "Invalid OTP Code",
    INVALID_LOGIN_CREDENTIALS: "Account credentials are invalid",
    INCORRECT_PIN: "The PIN you entered is incorrect",
    INVALID_PIN: "PIN must be a 4 digit number",
    ACCOUNT_DOES_NOT_EXIST: "Account number does not exist",
    INVALID_AMOUNT: "Amount is invalid",
    TRANSACTION_FAILED: "Transaction failed",
    INVALID_SMARTCARD_NUMBER: "Smartcard number is invalid",
    CABLETV_ACCOUNT_NOT_FOUND: "Account not found",
    SESSION_TIMEOUT: "Session timeout, Please login again",
    INCORRECT_DETAILS: "One or more details are invalid",
    ACCOUNT_EXISTS: "Account with provided number exists",
    NIP_LOOKUP_FAILED: "Account details cannot be retrieved",
    DATE_RANGE_ERROR: "Date range is invalid",
    INVALID_BVN: "BVN number is invalid",
    INVALID_EMAIL: "Invalid email address",
    PIN_MISMATCH: "Both PINs do not match",
    EMAIL_MISMATCH: "Email addresses provided do not match",
    PASSWORD_RESET_DEFAULT_MESSAGE: "An email has been sent to the provided email if it exists",
    PIN_TYPE_NOT_SELECTED: "Please select a PIN type",
    ERROR_PROCESSING_REQUEST: "Error Occurred While Processing Request",
    INVALID_DIGITAL_ACCESS_CODE: "Invalid Digital Access Code",
    SOURCE_ACCOUNT_NOT_SELECTED: "Source account not selected",
    INSUFFICIENT_FUNDS: "Insufficient funds in account"
}

export const PURCHASE_STAGE = {
    AMOUNT_FORM: 0,
    CONFIRMATION_PAGE: 1,
    PIN_FORM: 2,
    OTP_FORM: 3,
    TRANSACTION_RESPONSE: 4,
} 

export const ACCOUNT_TYPES = {
    SELF: "self",
    OTHERS: "others"
}

export const SERVICE_TYPES = {
    AIRTIME: "airtime",
    DATA: "data",
    WALLET_TRANSFER: "wallet",
    BANK_TRANSFER: "bank",
    CABLETV_SUBSCRIPTION: "cabletv",
    ELECTRICITY_PAYMENT: "electricity_payment",
}

export const NETWORKS = {
    GLO: "GLO",
    MTN: "MTN",
    "9MOBILE": "9MOBILE",
    AIRTEL: "AIRTEL"
}

export const WALLET_TRANSFER_STAGE = {
    ...PURCHASE_STAGE
}

export const BANK_TRANSFER_STAGE = {
    SELECT_BANK: "select_bank",
    ENTER_AMOUNT: "enter_amount",
    CONFIRMATION_PAGE: "confirmation_page",
    PIN_FORM: "pin_form",
    TRANSACTION_RESPONSE: "transaction_response",
    OTP_FORM: "otp_form"
}

export const CABLETV_PAY_STAGE = {
    SELECT_CABLETV: "select_cabletv",
    CONFIRMATION_PAGE: "confirmation_page",
    PIN_FORM: "pin_form",
    TRANSACTION_RESPONSE: "transaction_response",
    OTP_FORM: "otp_form"
}

export const TRANSACTION_ENDPOINTS = {
    WALLET_TRANSFER: process.env.REACT_APP_WALLET_TRANSFER_URL!,
    WALLET_TO_BANK_TRANSFER: process.env.REACT_APP_WALLET_TO_BANK_TRANSFER_URL!,
    NON_WALLET_TO_BANK_TRANSFER: process.env.REACT_APP_NON_WALLET_TO_BANK_TRANSFER_URL!,
    SAVINGS_TO_WALLET_TRANSFER: process.env.REACT_APP_SAVINGS_TO_WALLET_TRANSFER_URL!
}

export const TRANSACTION_TYPES = {
    WALLET_TRANSFER: "WALLET",
    WALLET_TO_BANK_TRANSFER: "WALLET",
    NON_WALLET_TO_BANK_TRANSFER: "NON WALLET",
    SAVINGS_TO_WALLET_TRANSFER: "SAVINGS TO WALLET"
}

export const APP_ACCT_TYPES = {
    WALLET: 'WALLET',
    SAVINGS: 'SAVINGS ACCOUNT',
    NON_WALLET: "NON WALLET"
}

export const ELECTRICITY_PAY_STAGE = {
    SELECT_DISCO: "select_disco",
    CONFIRMATION_PAGE: "confirmation_page",
    PIN_FORM: "pin_form",
    TRANSACTION_RESPONSE: "transaction_response",
    OTP_FORM: "otp_form",
    ENTER_AMOUNT: "enter_amount",
}

export const ELECTRICITY_VEND_TYPE = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID"
}

export const USER_TRANSACTION_TYPES = {
    CREDIT: "CREDIT",
    DEBIT: "DEBIT",
}

export const TRANSACTIONS_HISTORY = {
    WALLET: 'wallet',
    NON_WALLET: 'non_wallet',
}

export const routes = [
    {
      main: "Dashboard",
      path: "/dashboard",
      icon: "home",
      children: []
    }
  ]

  export const SYMBOL_TYPES = {
    NAIRA: "NAIRA",
  }

  export const NAIRA_SYMBOL = "₦"; 

  export const LOG_LEVEL_DEFAULTS = {
    url: `${BASE_ACCESS_URL}${LOG_LEVEL_URL}`,
    method: 'POST',
    headers: {},
    token: null,
    onUnauthorized: (failedToken: any) => {},
    timeout: 0,
    interval: 1000,
    level: 'trace',
    backoff: {
      multiplier: 2,
      jitter: 0.1,
      limit: 30000,
    },
    capacity: 500,
    stacktrace: {
      levels: ['trace', 'warn', 'error'],
      depth: 3,
      excess: 0,
    },
    timestamp: () => new Date().toISOString(),
    format: remote.json,
  };

  export const WALLET_ACCOUNT = 'WALLET_ACCOUNT';
  export const NON_WALLET_ACCOUNT = 'NON_WALLET_ACCOUNT';
  export const DEBIT_TRANSACTION = 'Debit';

  export const DATE_PICKER_INPUT = {
    START_DATE: "START_DATE",
    END_DATE: "END_DATE"
  }

  export const REGISTER_STAGE = {
    ACCOUNT_NUMBER_SCREEN: "ACCOUNT NUMBER SCREEN",
    PIN_VALIDATION_SCREEN: "PIN VALIDATION SCREEN",
    BVN_SCREEN: "BVN SCREEN",
    OTP_SCREEN: "OTP SCREEN",
    DIGITAL_ACCESS_CODE_SCREEN: "DIGITAL ACCESS CODE SCREEN",
    SECURITY_QUESTIONS: "SECURITY QUESTIONS SCREEN",
    PASSWORD_SCREEN: 'PASSWORD SCREEN',
    PIN_SCREEN: 'PIN SCREEN',
    EMAIL_SCREEN: 'EMAIL SCREEN',
    CREATE_PASSWORD_SCREEN: 'CREATE PASSWORD SCREEN'
  }

  export const SECURITY_QUESTIONS = {
    ONE: 1,
    TWO: 2,
  }

  export const RESET_PASSWORD_STAGE = {
    ACCOUNT_NUMBER_SCREEN: 'ACCOUNT NUMBER SCREEN',
    OTP_SCREEN: 'OTP SCREEN',
    PIN_SCREEN: 'PIN SCREEN',
    OPERATION_COMPLETED: 'OPERATION COMPLETED',
    NEW_PASSWORD_SCREEN: 'NEW PASSWORD SCREEN'
  }

  export const PIN_TYPES = {
    USSD: 'USSD',
    MOBILE_APP: 'ANDROID'
  }

  export const CLIENT_TYPES = {
    SELF_CARE: 'SELFCARE',
  }

  export const TOOLTIP_ERROR_MESSAGE = {
    ACCOUNT_NUMBER: 'Account number must be 10 digits',
    PIN: 'Internet Banking PIN must be 4 digits',
    OTP: 'OTP must be 6 digits',
    DAC: 'Digital Access Code must be 10 digits',
    EMAIL: 'Email Address format is invalid',
    NO_QUESTION_ANSWER: "Please provide an answer for the selected question"
  }

  export const OPERATION_HEADERS = {
    GKALA_TRANSFER: 'G-KALA Account Transfer',
    BANK_TRANSFER: 'Bank Transfer'
  }

  export const STATIC_URLS = {
    DASHBOARD_HOME: "/dashboard"
  }

  export const USER_ACCOUNT_TYPES = {
    WALLET: "Wallet",
    SAVINGS: "Savings",
    INDIVIDUAL_CURRENT_ACCOUNT: "Individual Current Account",
  }

  export const ACCOUNT_TRAN_TYPE = {
    DEBIT: "Debit",
    CREDIT: "Credit"
  }

  export const APP_CONSTANTS = {
    REQUEST_TIMEOUT: 300000,
    IDLE_ACTIVITY_TIMEOUT: 10000,
    TIME_FOR_NEW_RESET: 3000,
    API_REQUEST_RETRIES: 3,
    REQUEST_RETRY_DELAY: 1000
  }

  export const USER_ROLES = {
    ADMIN: "ADMIN"
  }