import { useEffect } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { IDLE_ACTIVITY_TIMEOUT_MINUTE } from "../../../../utils/config";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const IdleActivity = ({ children }: any) => {
  const { logout } = useAuth();
  let timer: any;

// this function sets the timer that logs out the user after 10 secs
const handleLogoutTimer = () => {
  resetTimer();
};

// this resets the timer if it exists.
const resetTimer = () => {
  timer = setTimeout(() => {
    // Listener clean up. Removes the existing event listener from the window
    events.forEach((item) => {
      window.removeEventListener(item, handleLogoutTimer);
    });
    // logs out user
    logoutAction();
  }, Number(IDLE_ACTIVITY_TIMEOUT_MINUTE) * 60 * 1000);
};

// when component mounts, it adds an event listeners to the window
// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
useEffect(() => {
  events.forEach((item) => {
    window.addEventListener(item, handleLogoutTimer);
  });

  return () => {
    events.forEach((item) => {
      window.removeEventListener(item, handleLogoutTimer);
    });
  }
}, []);


const logoutAction = () => {
  toast.dismiss();
  logout();
};

  return children;
};

export default IdleActivity;