import React, { useEffect, useRef, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
  useAsyncDebounce,
  useGlobalFilter,
  TableInstance,
} from "react-table";
import { CSVLink, CSVDownload } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./styles/table.scss";

import {
  APP_RESPONSES,
  DATE_PICKER_INPUT,
  USER_ROLES,
} from "../../../../utils/constants";

import { toastFailure } from "../../../../utils/toasts";

import DatePicker from "react-datepicker";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "react-datepicker/dist/react-datepicker.css";
import { Button, PageButton } from "../Button/Button";
import {
  decryptReduxValue,
  formatAmount,
  generateRandomNumber,
} from "../../../../utils/functions";
import { useAppSelector } from "../../../../hooks/useStore";
import { RootState } from "../../../../redux/store";
import useDataInfo from "../../../../hooks/useDataInfo";
import { Margin, usePDF } from "react-to-pdf";
import { exportToPDF } from "react-easy-export";
import { format } from "date-fns";

interface ITableProps {
  columns: any[];
  data: any[];
  isLoading: boolean;
  fetchTransactionHistory: (a: string, b: string) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (a: Date) => void;
  setEndDate: (e: Date) => void;
  tableRef: any;
  sum_credit: number;
  sum_debit: number;
  printTableToExcel: (printState: boolean) => boolean;
  printGlobalReportsToExcel: () => void;
  accountNumber: string;
  customerName: string;
}

export default function Table({
  columns,
  data,
  isLoading,
  fetchTransactionHistory,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  tableRef,
  sum_credit,
  sum_debit,
  printTableToExcel,
  printGlobalReportsToExcel,
  customerName,
  accountNumber,
}: ITableProps) {
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    selectedFlatRows,
    page,
    //rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 } as any,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;
  //const page = rows.slice(0, 10)

  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);
  const [downloadHistory, setDownloadHistory] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [canPrintToExcel, setCanPrintToExcel] = useState(false);
  const { auth: authRedux } = useAppSelector((state: RootState) => state);
  const [collapseActionColumn, setCollapseActionColumn] = useState(false);
  const { toPDF, targetRef: tableToPdfRef } = usePDF({
    filename: `Statement-${generateRandomNumber()}`,
    page: { margin: Margin.NONE },
  });

  /**
   * This function adds padding to each row of the transaction history table
   */
  const padRows = () => {
    const rowToPad = document.querySelectorAll("tbody tr");

    if (!rowToPad) return;

    rowToPad.forEach((row: any) => {
      row.style.lineHeight = 2.5;
    });
  };

  useEffect(() => {
    padRows();
  }, [page]);

  useEffect(() => {
    setLoadingData(isLoading);
  }, [isLoading]);

  const resetRowsPerPage = () => {
    setPageSize(50);
  };

  // Watches the state of the start and end date and triggers an api call to fetch the transaction history on change
  useEffect(() => {
    if (!startDate && !endDate) return;

    callFetchTransactionHistory(startDate, endDate);
  }, [startDate, endDate]);

  const callFetchTransactionHistory = (startDate: any, endDate: any) => {
    // Reset the number of rows to be displayed per page
    resetRowsPerPage();

    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    if (endDateTime < startDateTime) {
      toastFailure(APP_RESPONSES.DATE_RANGE_ERROR);

      return;
    }

    // Call the transaction history endpoint
    fetchTransactionHistory(startDate, endDate);
  };

  // Downloads the history into an excel file
  useEffect(() => {
    if (downloadHistory && pageSize === data.length) {
      const response = printTableToExcel(true);
      setCanPrintToExcel((prev) => response);
    }
  }, [pageSize, downloadHistory]);

  useEffect(() => {
    if (!canPrintToExcel) return;

    const downloadButton: HTMLButtonElement | null = document.querySelector(
      "#test-table-xls-button"
    );

    // Click on the main download button
    if (downloadButton) downloadButton.click();

    // Reset the downloadHistory value
    setDownloadHistory(false);

    // Reset the page size
    setPageSize(50);

    setCanPrintToExcel(false);

    printTableToExcel(false);
  }, [canPrintToExcel]);

  const listAllHistoryInTable = (ev: any) => {
    setPageSize(data.length);
    setDownloadHistory(true);
  };

  const hasTransactions = () => {
    return data.length > 0;
  };

  const addOneHourToDate = (date: Date) => {
    return new Date(date.getTime() + 60 * 60 * 1000);
  };

  const showAccountDetailsForCustomer = () => {
    document.querySelector("#statement-account-details")!.innerHTML =
      customerName;
    document.querySelector("#statement-account-details-number")!.innerHTML =
      accountNumber.toString();
  };

  useEffect(() => {
    if (collapseActionColumn === true) {
      showAccountDetailsForCustomer();

      exportToPDF(
        tableToPdfRef.current.outerHTML,
        `Statement-${generateRandomNumber()}`
      );

      setCollapseActionColumn(false);
    }
  }, [collapseActionColumn]);

  const exportTableToPDF = () => {
    setCollapseActionColumn(true);

    // const printWindow = window.open("");

    // if (!printWindow) return;

    // printWindow.document.body.innerHTML = tableToPdfRef.current.outerHTML;
    // printWindow.document.title = `Statement-${generateRandomNumber()}`;

    // printWindow.document.close();
    // printWindow.close();

    // setCollapseActionColumn(false);
  };

  return (
    <div className="table-container container-padding">
      <div className="row justify-content-between features">
        <div className="left-section col-md-6 col-sm-12 d-flex mb-2 px-0 w-0">
          {/* Start Date and End Date Datepicker */}
          <section
            id="date-pickers"
            className="date-range d-flex justify-content-between px-0"
            ref={tableRef}
          >
            <div className="d-flex">
              <strong>From:</strong>
              <DatePicker
                id="start-date"
                selected={startDate}
                maxDate={new Date()}
                dateFormat={"dd-MM-yyyy"}
                onChange={(date) => {
                  setStartDate(date!);
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>

            <div className="d-flex ms-1">
              <strong>To:</strong>
              <DatePicker
                id="end-date"
                selected={endDate}
                maxDate={new Date()}
                dateFormat={"dd-MM-yyyy"}
                onChange={(date) => setEndDate(date!)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </section>
        </div>

        {!isLoading && hasTransactions() && (
          <div
            className="right-section col-md-6 col-sm-12 d-flex w-100"
            style={{ justifyContent: "space-between" }}
          >
            <div className="col-md-6 col-sm-12 mb-2">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>

            <div
              className="col-sm-12 col-md-3 px-0 d-grid mb-2 position-relative"
              style={{ width: "22%", height: "3rem" }}
            >
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Download Statement
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={listAllHistoryInTable}
                    >
                      Download as CSV
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => exportTableToPDF()}
                    >
                      Download as PDF
                    </a>
                  </li>
                </ul>
              </div>
              {/* Previous button code */}
              {/* <button
                className="btn btn-primary position-absolute w-100 h-100 download-in-excel"
                onClick={listAllHistoryInTable}
              >
                <i
                  className="fas fa-download me-2"
                  style={{ height: "0.875rem", width: "0.875rem" }}
                ></i>
                Download Statement
              </button> */}
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-primary invisible"
                table="table-to-xls"
                filename={`Statement-${generateRandomNumber()}`}
                sheet="tablexls"
                buttonText="Download Statement"
              />
              {/* End previous button code */}
            </div>
          </div>
        )}
      </div>

      {data.length > 0 && (
        <div className="mod-mt-2 d-flex flex-column">
          <div className="-mod-my-2 mod-overflow-x-auto -mod-mx-4 sm:-mod-mx-6 lg:-mod-mx-8">
            <div className="mod-py-2 mod-align-middle mod-inline-block mod-min-w-full sm:mod-px-6 lg:mod-px-8">
              <div
                className="mod-shadow overflow-hidden mod-border-b mod-border-gray-200 mod-rounded-lg"
                ref={tableToPdfRef}
              >
                {/* This section is shown when saving the transaction history as PDF */}
                {collapseActionColumn && (
                  <div
                    id="account-details-for-pdf"
                    style={{
                      width: "50rem",
                      fontSize: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.2rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <p
                      style={{ width: "100%", margin: "0rem", display: "flex" }}
                    >
                      <strong
                        style={{
                          width: "10rem",
                          textAlign: "end",
                          marginRight: "0.3rem",
                        }}
                      >
                        Account name:
                      </strong>{" "}
                      <span id="statement-account-details"></span>
                    </p>
                    <p
                      style={{ width: "100%", margin: "0rem", display: "flex" }}
                    >
                      <strong
                        style={{
                          width: "10rem",
                          textAlign: "end",
                          marginRight: "0.3rem",
                        }}
                      >
                        Account number:
                      </strong>{" "}
                      <span id="statement-account-details-number"></span>
                    </p>
                    <p
                      style={{ width: "100%", margin: "0rem", display: "flex" }}
                    >
                      <strong
                        style={{
                          width: "10rem",
                          textAlign: "end",
                          marginRight: "0.3rem",
                        }}
                      >
                        Start date:
                      </strong>{" "}
                      {format(startDate!, "dd-MMM-yyyy")}
                    </p>
                    <p
                      style={{ width: "100%", margin: "0rem", display: "flex" }}
                    >
                      <strong
                        style={{
                          width: "10rem",
                          textAlign: "end",
                          marginRight: "0.3rem",
                        }}
                      >
                        End date:
                      </strong>{" "}
                      {format(endDate!, "dd-MMM-yyyy")}
                    </p>
                  </div>
                )}
                <table
                  {...getTableProps()}
                  id="table-to-xls"
                  className="table table-bordered table-striped mod-min-w-full mod-divide-y mod-divide-gray-200 mt-3"
                >
                  <thead className="transaction-title py-2 px-4 mod-bg-gray-50">
                    {headerGroups.map(
                      (headerGroup: {
                        getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLTableRowElement> &
                          React.HTMLAttributes<HTMLTableRowElement>;
                        headers: any[];
                      }) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, index) => {
                            if (
                              collapseActionColumn &&
                              column.Header === "Action"
                            ) {
                              return null;
                            }

                            return (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                key={index}
                                className="mod-px-3 mod-py-3 mod-text-xs mod-font-medium mod-text-gray-500 mod-uppercase mod-tracking-wider position-relative"
                                style={
                                  collapseActionColumn
                                    ? {
                                        fontSize: "0.6rem",
                                      }
                                    : {}
                                }
                              >
                                <div className="w-100 d-flex justify-content-between">
                                  <span>{column.render("Header")}</span>
                                  <span>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                      : ""}
                                  </span>
                                  <i
                                    className="fa fa-filter position-relative mod-right-[0.1rem] mod-top-[0.2rem]"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      )
                    )}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="bg-white mod-divide-y mod-divide-gray-200"
                  >
                    {page.map(
                      (
                        row: {
                          getRowProps: () => JSX.IntrinsicAttributes &
                            React.ClassAttributes<HTMLTableRowElement> &
                            React.HTMLAttributes<HTMLTableRowElement>;
                          cells: any[];
                        },
                        i: any
                      ) => {
                        prepareRow(row);
                        return (
                          <>
                            <tr
                              {...row.getRowProps()}
                              key={i}
                              style={
                                collapseActionColumn
                                  ? {
                                      backgroundColor:
                                        i % 2 === 0 ? "#efefef" : "white",
                                    }
                                  : {}
                              }
                            >
                              {row.cells.map((cell, index) => {
                                if (
                                  collapseActionColumn &&
                                  cell.column.Header === "Action"
                                ) {
                                  return null;
                                }
                                return (
                                  <td
                                    label={row.cells[index].column.Header}
                                    {...cell.getCellProps()}
                                    className="mod-px-3 mod-py-4 mod-whitespace-nowrap text-sm"
                                    style={
                                      collapseActionColumn
                                        ? {
                                            fontSize: "0.6rem",
                                            textAlign:
                                              cell.column.Header !==
                                              "Description"
                                                ? "center"
                                                : "start",
                                            alignItems: "flex-start",
                                            verticalAlign: "top",
                                            width: "6rem",
                                            paddingRight: "1rem",
                                            lineHeight: "1.1rem",
                                          }
                                        : {}
                                    }
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                            {collapseActionColumn && (
                              <tr
                                style={{
                                  lineHeight: "0.1rem",
                                }}
                              >
                                <td
                                  style={{
                                    lineHeight: "0.1rem",
                                  }}
                                ></td>
                              </tr>
                            )}
                          </>
                        );
                      }
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td>{formatAmount(sum_credit.toString())}</td>
                      <td>{formatAmount(sum_debit.toString())}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* New Pagination setup */}
      {data.length > 0 && (
        <div
          className="mod-py-3 d-flex align-items-center justify-content-between"
          style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
        >
          <div className="mod-flex-1 d-flex justify-content-between sm:mod-hidden">
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </div>
          <div className="d-none sm:mod-flex-1 sm:mod-flex sm:mod-items-center sm:mod-justify-between">
            <div className="d-flex mod-gap-x-2 mod-text-sm mod-text-gray-700 mod-items-baseline">
              Page{" "}
              <span className="mod-font-medium">{state.pageIndex + 1}</span> of{" "}
              <span className="mod-font-medium">{pageOptions.length}</span>
              <select
                className="mod-mt-1 d-block w-100 mod-rounded-md mod-border-gray-300 mod-shadow-sm focus:mod-border-indigo-300 focus:mod-ring focus:mod-ring-indigo-200 focus:mod-ring-opacity-50 mod-p-2"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[50, 100, 1000].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <nav
                className="position-relative z-0 mod-inline-flex mod-rounded-md mod-shadow-sm -mod-space-x-px"
                aria-label="Pagination"
              >
                <PageButton
                  className="mod-rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  className="mod-rounded-r-md"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="mod-h-5 mod-w-5"
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      )}

      {/* Displayed if no transaction history is found */}
      {!isLoading && data.length === 0 && (
        <h3 className="w-100 text-center mt-4">No transaction found</h3>
      )}
    </div>
  );
}

export const Checkbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const count = preGlobalFilteredRows && preGlobalFilteredRows.length;

  return (
    <span className="h-100">
      {" "}
      <input
        id="filter-box"
        className="d-block w-100 mod-rounded-md mod-border-gray-300 mod-shadow-sm focus:mod-border-indigo-300 focus:mod-ring focus:mod-ring-indigo-200 focus:mod-ring-opacity-50 form-control"
        // style={{ display: 'inline', padding: "0.4rem 2rem", verticalAlign: "middle"}}
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} total records...`}
      />
    </span>
  );
};

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: any) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};
