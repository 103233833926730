import { CLEAR_REDUX_STORE, REGISTER_TOKEN, SIGN_IN, STORE_OTP_CODE } from '../redux/actions'
import { RootState } from '../redux/store'
import { IGKalaPinVerify, NewUserRecord, IOtpPayload, IBVNDetails } from '../interfaces/auth'
import { useAppDispatch, useAppSelector } from './useStore'
import { decryptReduxValue, getAbortSignalConfig } from '../utils/functions'
import { 
    CHECK_ACCOUNT_VALIDITY,
    CHECK_IF_REGISTERED_URL,
    CREATE_ACCOUNT_URL,
    GET_BENEFICIARY_PROFILE,
    LOGIN_URL, 
    RESET_PASSWORD_URL, 
    SECURITY_QUESTIONS_URL, 
    SEND_DIGITAL_ACCESS_CODE, 
    SEND_OTP_SMS_URL, 
    TOKENS_URL, 
    VALIDATE_ACCOUNT_AND_SEND_OTP, 
    VALIDATE_BVN_DETAILS_URL, 
    VALIDATE_CUSTOMER_DETAILS_URL, 
    VALIDATE_DIGITAL_ACCESS_CODE, 
    VALIDATE_OTP_TO_ACCOUNT, 
    VALIDATE_OTP_URL, 
    VALIDATE_USSD_PIN_URL, 
    VIEW_PROFILE_URL
} from '../api/urlConfig'
import { globalConfig } from './useAxios';
import  customAxiosApi from '../api/axios';
import useAxiosPrivate from './useAxiosPrivate';
import { encryptData } from '../utils/functions';

// Logout actions for the redux store
import { authReset } from '../redux/features/authSlice';
import { userReset } from '../redux/features/userAccountsSlice';
import { transactionReset } from '../redux/features/transactionSlice';
import { useNavigate } from 'react-router-dom'

interface FormValues{
    userId: string;
    password: string;
}

const useAuth = () => {
    const dispatch = useAppDispatch();
    const authRedux = useAppSelector((state: RootState) => (state.auth));
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const loginUser = async (formValues: FormValues) => {
        return customAxiosApi.post(LOGIN_URL!, encryptData(formValues), globalConfig);
    }

    const getAccountProfile = async (userInput: string) => {
        
        return axiosPrivate.post(VIEW_PROFILE_URL!, encryptData({ "agentcode": userInput }), globalConfig);        

    }

    const doCheckAccountValidity = async (accountNumber: string) => {
        
        return customAxiosApi.post(CHECK_ACCOUNT_VALIDITY!, encryptData({ "userId": accountNumber }), globalConfig);

    }

    const saveUserRecord = async (userRecord: any) => {
        // console.log('Creating: ', userRecord);
        return axiosPrivate.post(CREATE_ACCOUNT_URL!, encryptData(userRecord), globalConfig);

    }

    const sendOTPRequest = async (otpPayload: any) => {
        // console.log('Account number for send OTP: ', otpPayload);
        return axiosPrivate.post(SEND_OTP_SMS_URL!, encryptData(otpPayload), globalConfig);
       
    }


    const initializeAccessToken = async (abortSignal: AbortSignal) => {

        return customAxiosApi.post(TOKENS_URL!, {}, globalConfig);
    }

    const clearReduxStore = () => {
        dispatch({type: CLEAR_REDUX_STORE})
    }

    const userIsAuth = () => {
        return authRedux.user === "" ? false : true;
    }

    const getAuthTokens = () => {
        return authRedux.tokens;
    }

    const validateUSSDPin = async (reqPayload: IGKalaPinVerify) => {
        
        return customAxiosApi.post(VALIDATE_USSD_PIN_URL!, encryptData(reqPayload), globalConfig);
    }

    const checkIfRegistered = async (accountNumber: string) => {

        return customAxiosApi.post(CHECK_IF_REGISTERED_URL!, encryptData({ "userId": accountNumber }), globalConfig);

    }

    const validateOTPRequest = async (payload: IOtpPayload) => {

        return axiosPrivate.post(VALIDATE_OTP_URL!, encryptData(payload), globalConfig);
       
    }

    const getBeneficiaryProfile = async (userInput: string) => {

        return axiosPrivate.post(GET_BENEFICIARY_PROFILE!, encryptData({ "agentcode": userInput }), globalConfig);        

    }

    const doValidateBVNDetails = async (bvnDetails: IBVNDetails) => {
        return axiosPrivate.post(VALIDATE_BVN_DETAILS_URL!, encryptData(bvnDetails), globalConfig);
    }

    const doValidateOTP = async (otp: IOtpPayload) => {
        return axiosPrivate.post(VALIDATE_OTP_URL!, encryptData(otp), globalConfig);
    }

    const getSecurityQuestions = () => {
        return  axiosPrivate.get(SECURITY_QUESTIONS_URL!, {...globalConfig});
    }

    const doResetPassword = (payload: any) => {
        return axiosPrivate.post(RESET_PASSWORD_URL!, encryptData(payload), globalConfig);
    }

    const doValidatePIN = (payload: any) => {
        // console.log('ValidatePIN', encryptData(payload));
        return axiosPrivate.post(VALIDATE_USSD_PIN_URL!, encryptData(payload), globalConfig);
    }

    const doSendDigitalAccessCode = (accountNumber: {}) => {
        // console.log('Account Number for DAC: ', accountNumber)
        return axiosPrivate.post(SEND_DIGITAL_ACCESS_CODE!, encryptData(accountNumber), globalConfig);
    }

    const doValidateDigitalAccessCode = (reqPayload: any) => {
        // console.log('DAC', reqPayload);
        return axiosPrivate.post(VALIDATE_DIGITAL_ACCESS_CODE!, encryptData(reqPayload), globalConfig);
    }

    const doValidateAccountAndSendOTP = (reqPayload: any) => {
        // console.log('Account validate', reqPayload)
        axiosPrivate.post(VALIDATE_ACCOUNT_AND_SEND_OTP!, encryptData(reqPayload), globalConfig);
    }

    const doValidateOTPToAccount = (reqPayload: any) => {
        // console.log('ValidateOTPToAccount: ', reqPayload)
        return axiosPrivate.post(VALIDATE_OTP_TO_ACCOUNT!, encryptData(reqPayload), globalConfig);
    }

    const logout = () => {
        dispatch(authReset(true));
        dispatch(userReset(true));
        dispatch(transactionReset(true));
    }

    return {
        loginUser,
        getAccountProfile,
        doCheckAccountValidity,
        checkIfRegistered,
        saveUserRecord,
        sendOTPRequest,
        initializeAccessToken,
        clearReduxStore,
        userIsAuth,
        getAuthTokens,
        validateUSSDPin,
        validateOTPRequest,
        getBeneficiaryProfile,
        doValidateBVNDetails,
        doValidateOTP,
        getSecurityQuestions,
        doResetPassword,
        doValidatePIN,
        doSendDigitalAccessCode,
        doValidateDigitalAccessCode,
        doValidateAccountAndSendOTP,
        doValidateOTPToAccount,
        logout
    }
}


export default useAuth;