import { Link, Outlet, useLocation } from "react-router-dom";
import TopNavbar from "../../components/TopNavbar/TopNavbar";
import LeftSidebar from "./components/LeftSidebar/LeftSidebar";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { RootState } from "../../redux/store";

import { routes } from "../..";
import { useEffect, useState } from "react";
import { RESPONSE_CODES, STATIC_URLS, TRANSACTION_TYPES } from "../../utils/constants";
import useDataInfo from "../../hooks/useDataInfo";
import { SET_TRANSACTION_HISTORY } from "../../redux/actions";
import NavigationDrawer from "./components/NavigationDrawer/NavigationDrawer";
import NavigationDrawerMobile from "./components/NavigationDrawerMobile/NavigationDrawerMobile";
import { decryptReduxValue } from "../../utils/functions";
import IdleActivity from "./components/IdleActivity/IdleActivity";


function Dashboard() {
  const breadcrumbs = useBreadcrumbs(routes);
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const location = useLocation();  
  const [isDashboardHome, setIsDashboardHome] = useState(true);
  const [greetingsText, setGreetingsText] = useState("");

  const scrollToTop = () => window.scrollTo(0, 0);

  // On page load, this hook monitors the time and displays the corresponding greeting for the time of the day
  useEffect(() => {
    const timeOfDayInterval = setInterval(() => {
      setGreetingsText(getTimeOfDay());
    }, 1000);

    return () => {
      clearInterval(timeOfDayInterval);
    }
  },[])

  const getTimeOfDay = () => {
    let myDate = new Date();
    const hrs = myDate.getHours();

    let greet = "Welcome";

    if (hrs < 12)
        greet = 'Good Morning';
    else if (hrs >= 12 && hrs < 17)
        greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs < 24)
        greet = 'Good Evening';

    return greet;
  }

  /* This hook sets a listener for a click event on the body.
     It hides the navigation drawer for small screens
  */ 
  useEffect(() =>  {

    scrollToTop();

    const body = document.querySelector('#content')!;
    
    const bodyClickListener = (event: any) => { 

      const navbarToggler = document.querySelector(".navbar-toggler") as HTMLDivElement;

      if(!navbarToggler) return;

      if(navbarToggler) navbarToggler.classList.remove('open');

      // Get the reference to the navigation drawer mobile-sub-menu
      const mobileSubMenu = document.querySelector('.mobile-sub-menu');

      if(!mobileSubMenu) return;

      if(mobileSubMenu.classList.contains('mobile-active-sub-menu')) {
        mobileSubMenu.classList.remove('mobile-active-sub-menu');
      }
    }

    // Listen for a click event on the root div
    body.addEventListener('click', bodyClickListener);

    return () => { body.removeEventListener('click', bodyClickListener) }
  },[])

  /* This hook sets a listener for a click event on the body.
     It hides the sub-menu of the navigation drawer if it is active for large screens.
  */ 
  useEffect(() => {
    const body = document.querySelector('body')!;

    const bodyClickListener = (event: any) => { 
      // Get the reference to the navigation drawer sub-menu
      const subMenu = document.querySelector('.sub-menu');

      if(!subMenu) return;

      if(subMenu.classList.contains('active-sub-menu')) {
        subMenu.classList.remove('active-sub-menu');
      }
    }

    // Listen for a click event on the root div
    body.addEventListener('click', bodyClickListener);

    return () => { body.removeEventListener('click', bodyClickListener) }
  },[])

  /**
   * This hook makes the page scroll to top on page load
   */
  useEffect(() => {
    scrollToTop();
    verifyDashboardHome();
  }, [location.pathname])

  // Returns true if the URL location matches the dashboard home URL
  const verifyDashboardHome = () => {
    setIsDashboardHome((prev) => (location.pathname === STATIC_URLS.DASHBOARD_HOME));
  }


  return (
    <>
      <TopNavbar />
      <div id="content" style={{ height: "100vh" }} className="dashboard-content container-padding-x">
        <div className="container-fluid px-0 h-100">
        <div className="row  d-block">
          {/* <LeftSidebar /> */}
          {/* <div className="position-relative p-0 col-lg-2 col-md-2">
            <NavigationDrawer />
            <NavigationDrawerMobile />
          </div> */}
          <div style={{ height: "100vh" }}  className="position-relative col-lg-10 col-md-10 d-flex flex-column justify-content-start align-items-center container-padding m-auto">
          <div className="main-div-subcontainer lg:tw-w-full">
            <div className="name-wrapper row w-100 lg:tw-mb-[1rem] tw-pt-4 tw-mb-0" style={{ paddingLeft: "1rem" }}>
              <div className="col-lg-12 pt-1 d-flex align-items-center justify-content-between md:tw-flex-row tw-flex-col tw-content-center">
                <div className="d-flex flex-wrap ps-2 tw-pb-4" id="breadcrumbs">
                  {breadcrumbs.map(({ match, breadcrumb }, index) =>
                    <Link key={match.pathname} to={match.pathname} className={`d-flex align-items-center ${index === 0 ? "d-none": ""}`}>
                      {breadcrumb} { index < breadcrumbs.length - 1 ? "> " : "" }&nbsp;
                    </Link> 
                  )}
                </div>
                { 
                  isDashboardHome && <h3 className={`text-6 fw-400 d-flex md:tw-flex-row tw-flex-col md:tw-gap-0 tw-gap-1 align-items-center fs-2 lg:tw-mb-[unset] tw-mb-4`} id="welcome-text"><span className="md:tw-text-inherit tw-text-[1.1rem]">{ greetingsText }</span><span className="border text-success bg-white rounded-pill fw-500 md:tw-text-[0.9rem] text-3 px-3 py-1 ms-2">{ decryptReduxValue(authRedux.user).firstname }</span></h3>
                }
              </div>
            </div>
            <div className="row w-100 d-flex md:tw-justify-center md:tw-items-start pb-5 tw-justify-start tw-items-start container-padding dashboard-outlet" style={{ height: "calc(100vh - 8rem)", paddingLeft: "1rem" }}>
              <Outlet />
            </div>
          </div>
          </div>
          
          {/* <RightSidebar /> */}
        </div>
        </div>
      </div>
      <IdleActivity />
    </>
  );
}

export default Dashboard;
