import { useEffect, useRef, useState } from "react";
import { Power } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { LOGOUT } from "../../redux/actions";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SiteLogo from "../../assets/mmpsb-logo.png";
import "./styles/topnavbar.scss";
import { routes } from "../../utils/constants";
import { toast } from "react-toastify";
import { decryptReduxValue } from "../../utils/functions";
import { logout } from "../../redux/features/authSlice";

function TopNavbar() {
  const authRedux: any = useAppSelector((state: RootState) => (state.auth));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(logout(true));
    toast.dismiss();
    //navigate('/login');
  }

  useEffect(() => {
    const navDrawerToggler = document.querySelector(".navbar-toggler");

    if(!navDrawerToggler) return

    const clickListener = () => {
      
      document.querySelector(".mobile-sub-menu")?.classList.toggle("mobile-active-sub-menu")
    
    }

    navDrawerToggler.addEventListener('click', clickListener);

    return () => { navDrawerToggler.removeEventListener('click', clickListener) }
  }, [])

  return (
    <header id="header-dashboard" style={{background: "#f1f1f1", display:"flex", justifyContent: "center"}}>
      
        <div className="header-row col-lg-10 dashboard-header d-flex justify-content-between">

        <div className="header-column d-flex justify-content-between">
            <div className="logo logo-dashboard">
              {" "}
              <Link className="d-flex" to={decryptReduxValue(authRedux.user).agentname ? "/dashboard" : "/"} title="G-Kala">
                <img src={SiteLogo} className="img-logo-dashboard mt-0" alt="G-Kala" />
              </Link>{" "}
            </div>
          </div>
        
       
          <div className="header-column justify-content-end d-flex align-items-center">
                  {
                    !decryptReduxValue(authRedux.user).agentname &&
                    <Link className="btn btn-primary signup-button" to="/signup/existing-user">
                      Sign Up
                    </Link>
                  }
                  
                  {
                    authRedux.isLoggedIn && 
                    <Power className="fs-4 text-success logout" onClick={ logoutUser } style={{ cursor: 'pointer' }} />
                  }
            
          </div>
        </div>
  
    </header>
  );
}

export default TopNavbar;
