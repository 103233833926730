import { ReactNode, useEffect, useRef, useState } from "react";

type PasswordRevealProps = {
  id: string,
  children: ReactNode
}

const PasswordReveal = (props: PasswordRevealProps) => {
  const { id, children } = props;
  const iconRef = useRef<HTMLElement>(null);
  const passInputElement = document.querySelector(id);
  const INPUT_TYPES = {
    PASSWORD: "password",
    TEXT: "text"
  }

  const changeIcon = (newInputType: string) => {
    if(newInputType === INPUT_TYPES.PASSWORD){
      iconRef.current?.classList.replace("fa-eye-slash", "fa-eye")
    }else{
      iconRef.current?.classList.replace("fa-eye", "fa-eye-slash")
    }
    
  }

  const handleDisplayToggle = () => {
    if(!passInputElement) return;

    let newInputType = passInputElement.getAttribute("type") === INPUT_TYPES.PASSWORD ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD

    changeIcon(newInputType);

    // Change input type. Text or Password
    passInputElement?.setAttribute("type", newInputType);
  }
  
  return (
    <div className="w-100 d-flex flex-column align-items-end justify-content-center tw-z-0">
      {/* Children renders the password input field  */}
      { children }

      <div className="tw-w-[2rem] tw-h-auto tw-absolute tw-z-30 tw-flex tw-justify-end tw-items-center"><i ref={iconRef} className={`fas fa-eye me-2 tw-text-[#9a9595] hover:tw-cursor-pointer`} onClick={handleDisplayToggle}></i></div>
    </div>
    
  )
}

export default PasswordReveal