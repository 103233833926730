import React, { ReactNode } from 'react'
import ReceiptLayout from '../ReceiptLayout/ReceiptLayout'
import { Margin, usePDF } from 'react-to-pdf';
import { IUserFullTransactionHistory } from '../../../../interfaces/auth';
import './styles/printModal.scss';
import { generateRandomNumber } from '../../../../utils/functions';

const PrintModal = ({ dismissModal, record}: { dismissModal: () => void, record: IUserFullTransactionHistory}) => {

    const { toPDF, targetRef } = usePDF({filename: `receipt-${generateRandomNumber()}.pdf`, page: { margin: 10 }});

    const renderReceiptFullscreen = () => {
        const modalReceipt = document.querySelector(".receipt-container")!;
        const modal = document.querySelector(".modal")!;

        modal.innerHTML = "";
        
        modalReceipt.classList.add('print-resolution');
        modal.classList.add('print-resolution');
        modal.classList.add('full-receipt-view');

        modal.appendChild(modalReceipt);
    }

    const handlePDFGen = () => {
        renderReceiptFullscreen();
        toPDF();
    }


    return (
    <div className="modal show d-flex" tabIndex={-1} style={{ background: "rgba(0, 0, 0, 0.5)" }} onClick={dismissModal} ref={targetRef}>
        <div className="modal-dialog">
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-body text-center">
                <ReceiptLayout record={record} docRef={null} />            
            </div>
            <div className="modal-footer d-flex justify-content-around">
                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                <button type="button" className="btn btn-info text-white" onClick={dismissModal}>Close</button>
                <button type="button" className="btn btn-secondary text-white" onClick={() => handlePDFGen()}>Download</button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PrintModal